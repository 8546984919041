import React from 'react';
import img1 from '../../assets/images/aboutService/img1.jpg';
import img2 from '../../assets/images/aboutService/img2.jpg';
import img3 from '../../assets/images/aboutService/img3.jpg';
import style from './index.module.scss';

export default function AboutService() {
	return (
		<section className={style.aboutService}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>
						Точная диагностика по биологически активным точкам
					</h2>
				</div>
				<div className={style.list}>
					<div className={style.item}>
						<div className={style.imageContainer}>
							<img className={style.img} src={img1} alt='' />
						</div>
						<div className={style.heading}>
							Сигнал о неправильной работе вашего организма
						</div>
						<div className={style.description}>
							Все органы связаны с биологически активными (акупунктурными)
							точками на теле. Даже при малейших отклонениях в системах
							организма и органах, они начинают реагировать на эти изменения
							задолго до первых симптомов заболевания.
						</div>
					</div>
					<div className={style.item}>
						<div className={style.imageContainer}>
							<img className={style.img} src={img2} alt='' />
						</div>
						<div className={style.heading}>
							Выявление нарушений и скрытых заболеваний
						</div>
						<div className={style.description}>
							С помощью аппарата SANATER снимаются показания электрического
							сопротивления с поверхности рук, благодаря которым можно узнать
							состояние органов на стадии первичных нарушений и назначить
							правильное лечение!
						</div>
					</div>
					<div className={style.item}>
						<div className={style.imageContainer}>
							<img className={style.img} src={img3} alt='' />
						</div>
						<div className={style.heading}>Восстановление организма</div>
						<div className={style.description}>
							Воздействуя на лечебные точки электрическими импульсами,
							восстанавливается связь между больным органом и центральной
							нервной системой. Тем самым устраняется первопричина болезни.
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

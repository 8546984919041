import React from 'react';
import Header from '../../components/Header/Header.jsx';
import SentForm from '../../components/SentForm/SentForm.jsx';
import Footer from '../../components/Footer/Footer.jsx';

export default function Sent({ landingData }) {
	return (
		<>
			<Header landingData={landingData} />
			<SentForm />
			<Footer landingData={landingData} />
		</>
	);
}

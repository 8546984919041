// регулярка для редактирования телефона в формат +7 (999) 999-99-99
export function getMaskPhone(defaultPhone) {
	const req = /^([7])(\d{3})(\d{3})(\d{2})(\d{2})$/gm;
	defaultPhone = String(defaultPhone);
	return defaultPhone.replace(req, '+$1 ($2) $3-$4-$5');
}
// регулярка для редактирования телефона для отправки на backend 79999999999
export function getUnMaskPhone(defaultPhone) {
	const req = /[+](\d)\s\S(\d{3})\S\s(\d{3})\S(\d{2})\S(\d{2})/gm;
	defaultPhone = String(defaultPhone);
	return defaultPhone.replace(req, '$1$2$3$4$5');
}

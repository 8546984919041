import React from 'react';
import style from './index.module.scss';

export default function Appeal({ toggleModal }) {
	return (
		<section className={style.appeal}>
			<div className={style.inner}>
				<div className={style.heading}>
					<div className={style.title}>
						Узнайте о состоянии&nbsp;своего
						<br />
						организма&nbsp;всего за 40 минут
					</div>
				</div>
				<div onClick={toggleModal} className={style.button}>
					Узнать!
				</div>
			</div>
		</section>
	);
}

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header.jsx';
import ModalBackCall from '../ModalBackCall/ModalBackCall.jsx';
import TopScreenVideo from '../TopScreenVideo/TopScreenVideo.jsx';
import Features from '../Features/Features.jsx';
import Quiz from '../Quiz/Quiz.jsx';
import AboutService from '../AboutService/AboutService.jsx';
import Disorders from '../Disorders/Disorders.jsx';
import Appeal from '../Appeal/Appeal.jsx';
import Faq from '../Faq/Faq.jsx';
import Comments from '../Comments/Comments.jsx';
import Sertificates from '../Sertificates/Sertificates.jsx';
import YandexMap from '../YandexMap/YandexMap.jsx';
import Footer from '../Footer/Footer.jsx';
import Preloading from '../Preloading/Preloading.jsx';
import style from './index.module.scss';

export default function App({ landingData, isLoading }) {
	const [openModal, setOpenModal] = React.useState(false); // хук модального окна для записи

	let yandex;
	let jivo;

	try {
		yandex = landingData?.metrika[0]?.yandex;
		jivo = landingData?.metrika[0]?.jivo;
	} catch {
		yandex = '';
		jivo = '';
	}

	// функция изменения состояния модального окна
	const toggleModal = event => {
		event.stopPropagation();
		setOpenModal(!openModal);
	};

	return (
		<div className={style.app}>
			{!isLoading && landingData.head ? (
				<>
					{/* библиотека динамически подставляет данные в head */}
					<Helmet>
						{jivo && <script src={jivo} type='text/javascript' async />}
						{yandex && (
							<script
								src={window.ym(yandex, 'init', {
									clickmap: true,
									trackLinks: true,
									accurateTrackBounce: true,
									webvisor: true,
								})}
								type='text/javascript'
								async
							/>
						)}
					</Helmet>

					<Header
						landingData={landingData}
						toggleModal={toggleModal}
						yandex={yandex}
					/>
					{openModal && (
						<ModalBackCall
							toggleModal={toggleModal}
							landingData={landingData}
							yandex={yandex}
						/>
					)}
					<TopScreenVideo
						landingData={landingData}
						toggleModal={toggleModal}
						yandex={yandex}
					/>
					<Features />
					<Quiz landingData={landingData} yandex={yandex} />
					<AboutService />
					<Disorders />
					<Appeal toggleModal={toggleModal} />
					<Faq />
					<Comments landingData={landingData} />
					<Sertificates />
					<YandexMap landingData={landingData} yandex={yandex} />
					<Footer landingData={landingData} toggleModal={toggleModal} />
				</>
			) : (
				<Preloading />
			)}
		</div>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import vk from '../../assets/images/sent/vk.svg';
import facebook from '../../assets/images/sent/facebook.svg';
import twitter from '../../assets/images/sent/twitter.svg';

export default function SentForm() {
	return (
		<div className={style.sentForm}>
			<div className={style.inner}>
				<div className={style.row}>
					<div className={style.wrapper}>
						<Link to='/'>
							<div className={style.buttonBack}>Вернуться</div>
						</Link>
						<div className={style.container}>
							<div className={style.col}>
								<h1 className={style.title}>Спасибо за заявку!</h1>
								<h2 className={style.subtitle}>
									Администратор свяжется с Вами в течение 3 минут.
								</h2>
								<h2 className={style.appeal}>
									Поделитесь здоровьем с друзьями:
								</h2>
								<div className={style.socials}>
									<a
										className={style.link}
										href='https://oauth.vk.com/authorize?client_id=-1&redirect_uri=https%3A%2F%2Fvk.com%2Fshare.php%3Furl%3Dhttp%253A%252F%252Fsanatera.plahotinandrei.ru%252F%26title%3DSanatera%26description%3DDESC%26image%3DIMG_PATH%26noparse%3Dtrue&display=widget'
									>
										<span className={style.icon}>
											<img className={style.imgIcon} src={vk} alt='vk-logo' />
										</span>
										<span className={style.button}>Поделиться</span>
									</a>
									<a
										className={style.link}
										href='http://www.facebook.com/sharer.php?s=100&p[title]=Sanatera&p[summary]=DESC&p[url]=http%3A%2F%2Fsanatera.plahotinandrei.ru%2F&p[images][0]=IMG_PATH'
									>
										<span className={style.icon}>
											<img
												className={style.imgIcon}
												src={facebook}
												alt='facebook-logo'
											/>
										</span>
										<span className={style.button}>Поделиться</span>
									</a>
									<a
										className={style.link}
										href='https://twitter.com/share?text=Sanatera&url=http%3A%2F%2Fsanatera.plahotinandrei.ru%2F&counturl=http%3A%2F%2Fsanatera.plahotinandrei.ru%2F'
									>
										<span className={style.icon}>
											<img
												className={style.imgIcon}
												src={twitter}
												alt='twitter-logo'
											/>
										</span>
										<span className={style.button}>Tweet</span>
									</a>
								</div>
								<p className={style.text}>
									или подписывайтесь на нас в социальных сетях, чтобы получать{' '}
									<em>полезные советы</em>, а также участвовать в акциях,
									доступных только подписчикам.{' '}
								</p>
							</div>
							<div className={style.img}></div>
						</div>
					</div>
				</div>
				<div className={style.wrapper}></div>
			</div>
		</div>
	);
}

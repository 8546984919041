// разбирает преобразованную строку из вложенного объекта в читабельный вид
export function getQuizDataParsing(data) {
	let notes = JSON.stringify(data);
	notes = notes.replaceAll('"', '');
	notes = notes.replaceAll('}', '');
	notes = notes.replaceAll('{', '');
	notes = notes.replaceAll('::', ' -> ');
	notes = notes.replaceAll(':', ' -> ');
	notes = notes.replaceAll(',', '\n');
	return notes;
}

import React from 'react';
import style from './index.module.scss';

export default function ModalItem({ dataModal, toggleModalSlider }) {
	return (
		<>
			<div className={style.background} onClick={toggleModalSlider}></div>
			<div className={style.container}>
				<div className={style.modal}>
					<div className={style.form}>
						<div className={style.close} onClick={toggleModalSlider}>
							×
						</div>
						<div className={style.container}>
							<img className={style.image} src={dataModal.img} alt='' />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

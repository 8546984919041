/* eslint-disable no-undef */
import React from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import ModalPolicy from '../ModalPolicy/ModalPolicy.jsx';
import { getUnMaskPhone } from '../../utils/maskPhone';
import style from '../TopScreenVideo/index.module.scss';

export default function TopScreenVideo({ landingData, toggleModal, yandex }) {
	const navigation = useNavigate();
	const [showModalPolicy, setShowModalPolicy] = React.useState(false); // стейт модального окна политики конфиденциальности
	const [checkbox, setCheckbox] = React.useState(true); // стейт чекбокса

	let date = Date.now() + 432000000;
	let dateFormat = new Date(date).toLocaleString('ru', {
		month: 'long',
		day: 'numeric',
	});

	// получаем и записываем в стейт данные клиента
	const handleUser = event => {
		event.preventDefault();
		const nameClient = event.target.name.value;
		const phone = event.target.phone.value;
		const newPhone = getUnMaskPhone(phone);

		if (checkbox && phone) {
			let newUserData = {
				name: 'Клиент: ' + nameClient,
				phone: newPhone,
				department: landingData.department,
				status: 1,
				notes: '',
				city: landingData.city,
				email: '',
				url: window.location.href,
			};

			fetch(
				'https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/service_request/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify(newUserData),
				}
			)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					ym(yandex, 'reachGoal', 'SendingCallbackForm');
					_tmr.push({
						type: 'reachGoal',
						id: 3314673,
						value: 900,
						goal: 'Otpravkaforma',
					});
				})
				.catch(e => {
					console.log(e);
					navigation('/error');
				});
			navigation('/sent');
		}
	};

	// переключение модального окна
	const toggleModalPolicy = () => {
		setShowModalPolicy(!showModalPolicy);
	};
	// переключение чекбокса
	const switchCheck = () => {
		setCheckbox(!checkbox);
	};

	return (
		<section className={style.topScreenVideo}>
			{showModalPolicy && (
				<ModalPolicy
					landingData={landingData}
					toggleModalPolicy={toggleModalPolicy}
				/>
			)}

			<div className={style.background}></div>
			<div className={style.inner}>
				<div className={style.wrapper}>
					<header className={style.header}>
						<h1 className={style.title}>Один сеанс вместо тысячи анализов!</h1>
						<h2 className={style.subtitle}>
							Инновационная диагностика 22 систем организма за 40 минут <br />
							<br />
							<span className={style.struck}>
								{landingData?.head[1].value}
							</span>{' '}
							<span className={style.newPrice}>
								{landingData?.head[0].value} руб
							</span>{' '}
							до
							<span className={style.date}> {dateFormat}</span>
						</h2>
					</header>
					<form
						onSubmit={handleUser}
						className={style.topScreenForm}
						action='#'
					>
						<div className={style.title}>
							Оставьте заявку и получите идеальный план лечения
						</div>
						<div className={style.subtitle}></div>
						<div className={style.inputs}>
							<input
								className={style.input}
								type='text'
								name='name'
								id='name'
								placeholder='Имя'
							/>
							<InputMask
								className={style.input}
								mask='+7 (999) 999-99-99'
								type='tel'
								name='phone'
								id='phone'
								placeholder='+7 (___) ___-__-__'
								required
							/>

							<button type='submit' className={style.submit}>
								Получить план лечения
							</button>
							<div onChange={switchCheck} className={style.check}>
								<div
									className={
										checkbox ? style.checkboxStyleActive : style.checkboxStyle
									}
								></div>
								<input
									type='checkbox'
									name='checkbox'
									id='checkbox'
									defaultChecked
									required
									className={style.checkbox}
								/>
								<label className={style.text} htmlFor='checkbox'>
									Подтверждаю, что я ознакомлен с{' '}
									<span onClick={toggleModalPolicy} className={style.link}>
										политикой конфиденциальности
									</span>{' '}
									и даю свое согласие на сбор и обработку персональных данных
								</label>
							</div>
						</div>
					</form>
					<div className={style.mobileAppeal}>
						<div className={style.title}>
							Оставьте заявку и получите идеальный план лечения
						</div>
						<div onClick={toggleModal} className={style.button}>
							Получить план лечения
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

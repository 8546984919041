import React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';

export default function ErrorComponent() {
	return (
		<div className={style.errorComponent}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h1 className={style.title}>
						Произошла ошибка, вернитесь на главную и повторите попытку{':('}
					</h1>
				</div>
				<Link to='/'>
					<div className={style.button}>Вернуться</div>
				</Link>
			</div>
		</div>
	);
}

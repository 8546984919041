import React from 'react';
import style from './index.module.scss';

export default function Disorders() {
	return (
		<section className={style.disorders}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>
						Выявление нарушений 22 систем организма
					</h2>
				</div>
				<div className={style.wrapper}>
					<ul className={style.list}>
						<li className={style.item}>Селезенка</li>
						<li className={style.item}>Печень</li>
						<li className={style.item}>Желудок</li>
						<li className={style.item}>
							Жировая дегенерация <span>(уровень холестерина)</span>
						</li>
						<li className={style.item}>
							Мочевой пузырь и репродуктивные органы
						</li>
						<li className={style.item}>
							Тонкий кишечник <span>(двенадцатиперстный)</span>
						</li>
						<li className={style.item}>Лимфатическая система</li>
						<li className={style.item}>Нервная система</li>
						<li className={style.item}>Аллергия</li>
						<li className={style.item}>Эндокринная система</li>
						<li className={style.item}>Тонкий кишечник</li>
					</ul>
					<ul className={style.list}>
						<li className={style.item}>Сердце</li>
						<li className={style.item}>Почки и мочеточники</li>
						<li className={style.item}>Поджелудочная железа</li>
						<li className={style.item}>Дегенерация суставов</li>
						<li className={style.item}>Соединительная ткань</li>
						<li className={style.item}>Желчный пузырь и желчные пути</li>
						<li className={style.item}>Дегенерация кожи</li>
						<li className={style.item}>Дыхательная система</li>
						<li className={style.item}>Толстый кишечник</li>
						<li className={style.item}>
							Кровообращение <span>(сосудистая система)</span>
						</li>
						<li className={style.item}>
							Паренхиматозные и эпителиальные ткани
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}

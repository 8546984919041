import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import './index.module.scss';
import Main from './pages/MainPage/MainPage.jsx';

const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(
	<HashRouter>
		<Main />
	</HashRouter>
);

import React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';

export default function NotFoundComponent() {
	
	return (
		<div className={style.notFoundComponent}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h1 className={style.title}>Страница не найдена {':('}</h1>
				</div>
				<Link to='/'><div className={style.button}>Вернуться на главную</div></Link>
			</div>
		</div>
	);
}

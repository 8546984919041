import React from 'react';
import style from './index.module.scss';

export default function Faq() {
	return (
		<section className={style.faq}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>Самые популярные вопросы</h2>
				</div>
				<ul className={style.list}>
					<details className={style.item}>
						<summary className={style.question}>
							Сколько длится комплексная диагностика?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								30-60 минут. Зависит от состояния вашего организма.
							</p>
						</div>
					</details>
					<details className={style.item}>
						<summary className={style.question}>
							Как подготовится к процедуре?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								За 2 часа пищу не принимать, исключить чай и кофе. Воду пить
								можно.
							</p>
						</div>
					</details>
					<details className={style.item}>
						<summary className={style.question}>
							Какие ощущения в процессе?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								Некоторые отмечают приятные покалывания на кончиках пальцев.
							</p>
						</div>
					</details>
					<details className={style.item}>
						<summary className={style.question}>
							Что вы получаете после процедуры?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								Врач расскажет в каком состоянии каждый ваш орган, выявит
								причину изменений. Вы получите рекомендации по питанию, о том,
								как улучшить образ жизни и, если необходимо – врач назначит
								лечение.
							</p>
						</div>
					</details>
				</ul>
			</div>
		</section>
	);
}

import React from 'react';
import style from '../Features/index.module.scss';
import img_1 from '../../assets/images/features/feature14.png';
import img_2 from '../../assets/images/features/feature3.png';
import img_3 from '../../assets/images/features/feature2.png';

export default function Features() {
	return (
		<section className={style.features}>
			<div className={style.inner}>
				<div className={style.listItems}>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_1} alt='feature' />
						</div>
						<p className={style.title}>Экономно</p>
						<p className={style.subtitle}>
							Одно обследование вместо сотни анализов и снимков сэкономит Ваши
							деньги и время
						</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_2} alt='feature' />
						</div>
						<p className={style.title}>Инновационный метод</p>
						<p className={style.subtitle}>
							Не вредно для организма, удобно, обследование всех органов за один
							раз
						</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_3} alt='feature' />
						</div>
						<p className={style.title}>Идеальный план лечения</p>
						<p className={style.subtitle}>
							Получите план лечения, разработанный с учетом особенностей Вашего
							организма
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
